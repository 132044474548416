@font-face {
  font-family: Stolzl;
  font-weight: 800;
  font-style: normal;
  src: url('../src/assets/fonts/Stolzl-Book.woff') format('woff');
}

@font-face {
  font-family: Stolzl;
  font-weight: 700;
  font-style: normal;
  src: url('../src/assets/fonts/Stolzl-Bold.woff') format('woff');
}

@font-face {
  font-family: Stolzl;
  font-weight: 600;
  font-style: normal;
  src: url('../src/assets/fonts/Stolzl-Thin.woff') format('woff');
}

@font-face {
  font-family: Stolzl;
  font-weight: 500;
  font-style: normal;
  src: url('../src/assets/fonts/Stolzl-Medium.woff') format('woff');
}

@font-face {
  font-family: Stolzl;
  font-weight: 400;
  font-style: normal;
  src: url('../src/assets/fonts/Stolzl-Regular.woff') format('woff');
}

@font-face {
  font-family: Stolzl;
  font-weight: 300;
  font-style: normal;
  src: url('../src/assets/fonts/Stolzl-Light.woff') format('woff');
}

body,
html,
input {
  margin: 0;
  width: 100%;
  font-weight: 300;
  font-family: 'Stolzl';
  font-size: 14px;
  background-color: #141414;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.Toastify__toast--info {
  color: #d0d0d0;
  background: #000000;
}

@media only screen and (max-width: 800px) {
  html,
  body,
  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  footer,
  header {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  html,
  body,
  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  footer,
  header {
    font-size: 10px;
  }
}
